<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface FormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
}
    import { Str } from '@supercharge/strings'
    import { FormInputData } from '~~/composables/useFormInput'
    const { $theme } = useNuxtApp();
    const { tenant } = useAuthStore();
    const props = defineProps<FormInputProps>();
    const inputData: FormInputData = useFormInput(props);
    const input = ref<HTMLInputElement | null>(null);
    const phone = ref<string>(inputData.model.value);
    const setPhone = (event: InputEvent) => {
        inputData.model.value = Str(event.target.value).strip().get();
    };
    const preferredCountries = computed(() => {
        if (tenant.billingCountry == 'fr') {
            return ['fr', 'be', 'nl', 'de', 'lu'];
        }
        return ['be', 'fr', 'nl', 'de', 'lu'];
    });
    onMounted(async () => {
        console.log('Phone Input mounted', phone.value, inputData.model.value);
        if (props.autofocus && input.value) {
            console.log('input mounted', input.value, props.autofocus);
            await wait(250);
            input.value?.focus();
        }
    });
</script>

<style>
    .vti__dropdown-list {
        padding: 12px;
        filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
        border-radius: 5px;
        border-color: #f3f4f6;
    }
    .vti__dropdown-item {
        padding: 6px 10px;
        border-radius: 5px;
        font-size: 13px;
        display: flex;
        align-items: center;
    }
    .vti__dropdown-item.highlighted {
        background: #f3f4f6;
    }
    .vti__dropdown-item.last-preferred {
        margin-bottom: 20px;
        position: relative;
        border-bottom: none;
    }
    .vti__dropdown-item.last-preferred:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 1px;
        background: #e5e7eb;
    }
    .vti__dropdown-item .vti__flag {
        margin-right: 12px;
    }
    .vti__dropdown-item strong {
        font-weight: 400;
        margin-right: 5px;
    }
    .vti__dropdown-arrow {
        display: none;
    }
    .vue-tel-input:focus-within {
        outline: none;
        box-shadow: none;
        border-color: #002ed8;
    }
    /* .vti__flag {
        width: 16px;
        height: 12px;
    } */
    .vti__dropdown {
        padding: 0;
    }
</style>

<template>
    <FormElement :inputData="inputData">
        <slot name="before-input" />
        <vue-tel-input
            :class="inputData.className.value"
            v-model="phone"
            mode="international"
            :preferred-countries="preferredCountries"
            :input-options="{
                placeholder: inputData.placeholder.value,
            }"
            @input="setPhone"
        />
        <slot name="after-input" />
    </FormElement>
</template>
